import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { SEO, Nav, Footer, SignUpBanner, SocialMediaButtons } from "@fishrmn/fishrmn-components";
import { socialMediaTypes } from "@fishrmn/fishrmn-types";

import "../css/styles.scss";
import "../css/grid.css";

// TEMP VALUES
import FishermanLogo from "../favicon-test.png";

// Banner static values
const successfulCallback = () =>
  new Promise(resolve => setTimeout(resolve, 3000));
const copy =
  "Please submit your Name and Email Address and we will contact you to verify.";
const header = "Is This Your Business? Claim This Website Now! ";

export default class DefaultLayout extends React.PureComponent {
  getCurrentPageMetadata(pagesMetadata) {
    if (this.props.location) {
      return pagesMetadata.filter(
        pageMetadata => pageMetadata.Path === this.props.location.pathname
      )[0];
    }
    return false;
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query PageMetadata {
            allAirtable {
              edges {
                node {
                  siteContent {
                    Business_Name
                    Social_Media_Links {
                      link
                      type
                    }
                    Configuration {
                      baseConfig {
                        siteTitle
                        siteTitleAlt
                        siteLogo
                        siteUrl
                        siteDescription
                      }
                      socialMediaConfig {
                        twitter {
                          companyTwitterHandle
                          image
                        }
                        facebook {
                          appID
                          type
                          image
                          restaurantMetadata {
                            address
                            locality
                            region
                            postalCode
                            country
                            email
                            phoneNumber
                          }
                        }
                      }
                    }
                  }
                  sitePages {
                    Title
                    Display_Title
                    Description
                    Path
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const queryData = data.allAirtable.edges[0].node;
          const pagesMetadata = queryData.sitePages;
          const businessName = queryData.siteContent.Business_Name;
          const config = queryData.siteContent.Configuration;
          const currentPageMetadata =
            this.getCurrentPageMetadata(pagesMetadata) || {};
          return (
            <div id="layout-container">
              <SEO
                config={config}
                path={currentPageMetadata.Path}
                title={currentPageMetadata.Title}
                description={config.baseConfig.siteDescription}
                smTypes={[socialMediaTypes.facebook, socialMediaTypes.twitter]}
              />
              <SignUpBanner
                claimed={true}
                copy={copy}
                header={header}
                subscriptionCallback={successfulCallback}
              />
              <Nav
                logo={<img />}
                links={pagesMetadata.map(pageMetadata => (
                  <Link
                    to={pageMetadata.Path}
                    style={{
                      textDecoration: "none"
                    }}
                  >
                    <h4>{pageMetadata.Display_Title}</h4>
                  </Link>
                ))}
              />
              <div id="page-container">{this.props.children}</div>
              <Footer businessName={businessName} />
            </div>
          );
        }}
      />
    );
  }
}
