import React from "react";
import AWS from "aws-sdk";
import { graphql } from "gatsby";
import { LayoutParser } from "@fishrmn/fishrmn-utils";
import {
  Hoo,
  Reviews,
  Location,
  ImageGallery,
  Call,
  ContactForm,
  Email
} from "@fishrmn/fishrmn-components";

import Layout from "../components/Layout";
import SectionLayouts from "../components/SectionLayouts";

import HomeBackground from "../data/hero1.jpg";
import LocationBackground from "../data/hero2.jpg";
import ContactBackground from "../data/hero3.jpg";

import Gallery1 from "../data/gallery1.jpg";
import Gallery2 from "../data/gallery2.jpg";

const GalleryImages = [Gallery1, Gallery2];

const params = (name, email, phone, about) => ({
  Destination: {
    /* required */
    ToAddresses: ["kauannataxpro@aol.com"]
  },
  Message: {
    /* required */
    Body: {
      /* required */
      Text: {
        Charset: "UTF-8",
        Data: `
          ${name} has reached out to you via your website contact form
          
          "${about}"
          Email: ${email}
          Phone: ${phone}
          
          By clicking reply to this email, the contacts email will be prepopulated 
          `
      }
    },
    Subject: {
      Charset: "UTF-8",
      Data: `Website contact form: ${name}`
    }
  },
  Source: "info@fishrmn.com",
  ReplyToAddresses: [email]
});

const subscriptionCallback = ({ name, phone, email, about }) =>
  new AWS.SES({ apiVersion: "2010-12-01" })
    .sendEmail(params(name, email, phone, about))
    .promise();

export default class extends React.Component {
  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  render() {
    const { siteContent } = this.props.data.allAirtable.edges[0].node;
    const images = siteContent.Images.filter(image => image.url).map(
      image => image.url
    );
    const pageData = {
      about: siteContent.About,
      address: siteContent.Business_Address,
      phone: siteContent.Business_Phone,
      hoo:
        siteContent.Hours_of_Operation.length > 1 &&
        siteContent.Hours_of_Operation,
      pageTitle: `Welcome to ${siteContent.Business_Name}`,
      pageTitleBackgroundColor: "#3d3d3d",
      businessName: siteContent.Business_Name,
      images: images.length > 0 && images,
      timezone: "America/New_York",
      reviews: siteContent.Reviews.map(review => ({
        content: review.text,
        source: review.data_source
      })).filter(
        review => review.source === "yelp" && review.content.length > 0
      )
    };
    const sectionLayouts = LayoutParser().parsePageLayout(
      [
        <div>
          <div id="home">
            <div style={{ backgroundImage: `url('${HomeBackground}')` }}>
              <div>
                <h1 id="about">{pageData.businessName}</h1>
                <Hoo
                  hours={pageData.hoo}
                  timezone={pageData.timezone}
                  showHours={false}
                />
              </div>
            </div>
            <div>
              <h1>Welcome</h1>
              <div>
                {pageData.about[0] ||
                  `${
                    pageData.businessName
                  } is a premier accounting firm based in Lancaster, TX. Our team of professionals has a track record of satisfied clients, and our committed workflow and specialty expertise is sure to suit your needs.`}
              </div>
              {/* {pageData.reviews.length > 0 && <h1>Reviews</h1>} */}
              {/* {pageData.reviews.length > 0 && <Reviews reviews={pageData.reviews}/>} */}
            </div>
          </div>
          <div id="location">
            <div style={{ backgroundImage: `url('${LocationBackground}')` }}>
              <div>
                <h1>Location</h1>
                <div>
                  <div>
                    <Hoo hours={pageData.hoo} timezone={pageData.timezone} />
                  </div>
                  <div>
                    <Location address={pageData.address} afterText="" name="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div id="gallery"> */}
          {/* <h1>Gallery</h1> */}
          {/* <ImageGallery images={pageData.images || GalleryImages} imagesPerRow={3} /> */}
          {/* </div> */}
          <div id="contact">
            <div>
              <div>
                <h1>Contact</h1>
                <Call number={pageData.phone} />
                <h1>Reach Out</h1>
                <ContactForm
                  subscriptionCallback={subscriptionCallback}
                  header=""
                  showTextArea
                  textAreaPlaceholder="Message"
                />
              </div>
            </div>
          </div>
        </div>
      ],
      pageData
    );
    return (
      <Layout location={this.props.location}>
        <SectionLayouts sectionLayouts={sectionLayouts} />
      </Layout>
    );
  }
}

export const query = graphql`
  query HomeQuery {
    allAirtable {
      edges {
        node {
          siteContent {
            Business_Address
            Business_Phone
            Hours_of_Operation
            Business_Name
            Images {
              url
            }
            About
            Reviews {
              text
              data_source
            }
          }
        }
      }
    }
  }
`;
